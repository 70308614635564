<template>
  <v-content>
    <v-container grid-list-lg fluid style="margin-bottom: 58px">
      <v-layout wrap align-start justify-start fill-height>
        <v-flex xs12 lg12 xl12>
          <app-title title="Grupos de atributos">
            <template slot="subtitle">
              Hay
              <span class="font-weight-bold">{{ products.length }}</span>
              en total
            </template>
          </app-title>
        </v-flex>
        <v-flex xs12 lg12 xl12>
          <v-card class="elevation-2">
            <v-data-table
              :headers="headers"
              :items="products"
              :pagination.sync="pagination"
              :rows-per-page-items="rowsPerPage"
              :loading="loading"
              class="elevation-0"
            >
              <template slot="items" slot-scope="props">
                <td class="text-xs-left">
                  <span v-html="props.item.name"></span>
                </td>
                <td class="text-xs-left">
                  <v-chip v-if="props.item.status" color="success" text-color="white" label>
                    <v-icon left>check</v-icon> Activo
                  </v-chip>
                  <v-chip v-else color="error" text-color="white" label> <v-icon left>clear</v-icon> Inactivo </v-chip>
                </td>
                <td class="text-xs-left">
                  <v-btn v-if="user.role.id !== 7" class="mx-0" color="info" small @click="openAtributo(props.item)">
                    Editar
                  </v-btn>
                  <v-btn
                    color="error"
                    small
                    :to="{
                      name: 'productAtributos',
                      params: { id: props.item.id }
                    }"
                  >
                    Atributos
                  </v-btn>
                  <v-btn
                    flat
                    color="success"
                    small
                    class="mx-0"
                    :to="{
                      name: 'productAtributosList',
                      params: { id: props.item.id }
                    }"
                  >
                    Productos
                  </v-btn>
                </td>
              </template>
            </v-data-table>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
    <v-dialog v-model="dialogEdit" persistent max-width="300px">
      <v-card>
        <v-card-title>
          <span class="headline"> Atributo </span>
        </v-card-title>
        <v-card-text>
          <v-layout row wrap>
            <v-flex xs12 sm12 md12>
              <v-text-field v-model="name" label="Nombre" outline clearable></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md12>
              <v-radio-group v-model="status" class="pa-0 ma-0" :mandatory="false">
                <v-radio color="secondary" label="Inactivo" :value="false"></v-radio>
                <v-radio color="secondary" label="Activo" :value="true"></v-radio>
              </v-radio-group>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="default" flat @click.native="dialogEdit = false">Volver</v-btn>
          <v-btn color="primary" flat @click.native="sendDialog">Actualizar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-btn v-if="user.role.id !== 7" fixed dark fab bottom right color="accent" @click="openAtributo">
      <v-icon> add </v-icon>
    </v-btn>
  </v-content>
</template>

<script>
import appTitle from '../useful/title.vue'
import { GET_PRODUCT_COLOR } from '../../config'

export default {
  name: 'Products',
  components: { appTitle },
  metaInfo: {
    title: 'Productos colores'
  },
  data: () => ({
    dialogEdit: false,
    loading: false,
    products: [],
    pagination: { rowsPerPage: 50 },
    rowsPerPage: [25, 50, 100, 200, { text: 'Todos', value: -1 }],
    headers: [
      {
        text: 'Nombre',
        align: 'left',
        sortable: false,
        value: 'name'
      },
      {
        text: 'Estado',
        align: 'left',
        sortable: false,
        value: 'status'
      },
      {
        text: '',
        align: 'left',
        sortable: false,
        value: ''
      }
    ],
    status: false,
    name: '',
    idEdit: null
  }),
  computed: {
    user() {
      return this.$store.getters.getUser
    }
  },
  mounted() {
    this.getProducts()
  },
  methods: {
    async getProducts() {
      const res = await this.$http.get(GET_PRODUCT_COLOR)
      this.products = res.data
    },
    async sendDialog() {
      try {
        const params = {
          name: this.name,
          status: this.status
        }
        if (this.idEdit) {
          await this.$http.put(`${GET_PRODUCT_COLOR}/${this.idEdit}`, params)
        } else {
          await this.$http.post(`${GET_PRODUCT_COLOR}`, params)
        }
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Actualizado'
        })
        this.dialogEdit = false
        this.getProducts()
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    openAtributo(i) {
      if (i) {
        this.idEdit = i.id
        this.name = i.name
        this.status = i.status
      }
      this.dialogEdit = true
    }
  }
}
</script>
